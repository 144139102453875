@font-face {
  font-family: "ClassyIcons";
  src: url('/assets/styles/fonts/ClassyIcons.eot');
  src: url('/assets/styles/fonts/ClassyIcons.eot') format('eot'),
  url('/assets/styles/fonts/ClassyIcons.woff') format('woff'),
  url('/assets/styles/fonts/ClassyIcons.ttf') format('truetype'),
  url('/assets/styles/fonts/ClassyIcons.svg') format('svg');
}

.ci {
  font-size: 40px;
  font-family: "ClassyIcons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

@function icon-char($filename) {
  $char: "";

@if $filename == 1000-strong {
$char: "\E001";
}
@if $filename == all-in-one {
$char: "\E002";
}
@if $filename == all-in-one_cloud-hosting {
$char: "\E003";
}
@if $filename == all-in-one_custom-receipts {
$char: "\E004";
}
@if $filename == all-in-one_dedication {
$char: "\E005";
}
@if $filename == all-in-one_flexible-payment {
$char: "\E006";
}
@if $filename == all-in-one_recurring-donations {
$char: "\E007";
}
@if $filename == all-in-one_reporting-analytics {
$char: "\E008";
}
@if $filename == all-in-one_safe-secure {
$char: "\E009";
}
@if $filename == back-to-top {
$char: "\E00A";
}
@if $filename == blog {
$char: "\E00B";
}
@if $filename == brand_custom-footers {
$char: "\E00C";
}
@if $filename == brand_domain-masking {
$char: "\E00D";
}
@if $filename == brand_mag-glass {
$char: "\E00E";
}
@if $filename == brand_social-media {
$char: "\E00F";
}
@if $filename == brand_website-customization {
$char: "\E010";
}
@if $filename == causes {
$char: "\E011";
}
@if $filename == checkmark {
$char: "\E012";
}
@if $filename == classy-101 {
$char: "\E013";
}
@if $filename == classy-awards {
$char: "\E014";
}
@if $filename == classy-community {
$char: "\E015";
}
@if $filename == classy-icon {
$char: "\E016";
}
@if $filename == classy-logo {
$char: "\E017";
}
@if $filename == close-x {
$char: "\E018";
}
@if $filename == contact-support {
$char: "\E019";
}
@if $filename == contact {
$char: "\E01A";
}
@if $filename == customer-stories {
$char: "\E01B";
}
@if $filename == design {
$char: "\E01C";
}
@if $filename == donations {
$char: "\E01D";
}
@if $filename == favorite-campaigns {
$char: "\E01E";
}
@if $filename == fundraising-ideas {
$char: "\E01F";
}
@if $filename == gallery {
$char: "\E020";
}
@if $filename == guides {
$char: "\E021";
}
@if $filename == heart {
$char: "\E022";
}
@if $filename == help-center {
$char: "\E023";
}
@if $filename == icon-popup-warning {
$char: "\E024";
}
@if $filename == impact {
$char: "\E025";
}
@if $filename == left-arrow {
$char: "\E026";
}
@if $filename == logout {
$char: "\E027";
}
@if $filename == magnify-glass-hover {
$char: "\E028";
}
@if $filename == megaphone {
$char: "\E029";
}
@if $filename == mobile-top-map {
$char: "\E02A";
}
@if $filename == mobile {
$char: "\E02B";
}
@if $filename == mobile_iPad {
$char: "\E02C";
}
@if $filename == mobile_iPhone {
$char: "\E02D";
}
@if $filename == mobile_top-supporters-will-love {
$char: "\E02E";
}
@if $filename == newsletter {
$char: "\E02F";
}
@if $filename == piggy-bank-icon {
$char: "\E030";
}
@if $filename == powered-by-pixabay {
$char: "\E031";
}
@if $filename == press-media {
$char: "\E032";
}
@if $filename == pricing_cc-explained {
$char: "\E033";
}
@if $filename == profile {
$char: "\E034";
}
@if $filename == reports-big-picture {
$char: "\E035";
}
@if $filename == reports-global-reports {
$char: "\E036";
}
@if $filename == reports-heart {
$char: "\E037";
}
@if $filename == reports-multiple-campaigns {
$char: "\E038";
}
@if $filename == right-arrow {
$char: "\E039";
}
@if $filename == salesforce_sf-logo {
$char: "\E03A";
}
@if $filename == salesforce_two-products {
$char: "\E03B";
}
@if $filename == settings {
$char: "\E03C";
}
@if $filename == share-circle {
$char: "\E03D";
}
@if $filename == step-one {
$char: "\E03E";
}
@if $filename == step-three {
$char: "\E03F";
}
@if $filename == step-two {
$char: "\E040";
}
@if $filename == story_first-platform {
$char: "\E041";
}
@if $filename == support {
$char: "\E042";
}
@if $filename == support_best-practices {
$char: "\E043";
}
@if $filename == support_dedicated-server {
$char: "\E044";
}
@if $filename == support_email-chat {
$char: "\E045";
}
@if $filename == support_phone {
$char: "\E046";
}
@if $filename == support_real-people {
$char: "\E047";
}
@if $filename == support_specialized-training {
$char: "\E048";
}
@if $filename == support_website-design {
$char: "\E049";
}
@if $filename == support_white-glove {
$char: "\E04A";
}
@if $filename == text {
$char: "\E04B";
}
@if $filename == video {
$char: "\E04C";
}
@if $filename == wave {
$char: "\E04D";
}
@if $filename == webinars {
$char: "\E04E";
}
@if $filename == making-requests {
$char: "\E04F";
}
@if $filename == authenticate {
$char: "\E050";
}
@if $filename == request-access {
$char: "\E051";
}

@return $char;
}

@mixin icon($filename, $insert: before) {
  &:#{$insert} {
    content: icon-char($filename);
  }
}

.ci-1000-strong {
  @include icon(1000-strong);
}
.ci-all-in-one {
  @include icon(all-in-one);
}
.ci-all-in-one_cloud-hosting {
  @include icon(all-in-one_cloud-hosting);
}
.ci-all-in-one_custom-receipts {
  @include icon(all-in-one_custom-receipts);
}
.ci-all-in-one_dedication {
  @include icon(all-in-one_dedication);
}
.ci-all-in-one_flexible-payment {
  @include icon(all-in-one_flexible-payment);
}
.ci-all-in-one_recurring-donations {
  @include icon(all-in-one_recurring-donations);
}
.ci-all-in-one_reporting-analytics {
  @include icon(all-in-one_reporting-analytics);
}
.ci-all-in-one_safe-secure {
  @include icon(all-in-one_safe-secure);
}
.ci-back-to-top {
  @include icon(back-to-top);
}
.ci-blog {
  @include icon(blog);
}
.ci-brand_custom-footers {
  @include icon(brand_custom-footers);
}
.ci-brand_domain-masking {
  @include icon(brand_domain-masking);
}
.ci-brand_mag-glass {
  @include icon(brand_mag-glass);
}
.ci-brand_social-media {
  @include icon(brand_social-media);
}
.ci-brand_website-customization {
  @include icon(brand_website-customization);
}
.ci-causes {
  @include icon(causes);
}
.ci-checkmark {
  @include icon(checkmark);
}
.ci-classy-101 {
  @include icon(classy-101);
}
.ci-classy-awards {
  @include icon(classy-awards);
}
.ci-classy-community {
  @include icon(classy-community);
}
.ci-classy-icon {
  @include icon(classy-icon);
}
.ci-classy-logo {
  @include icon(classy-logo);
}
.ci-close-x {
  @include icon(close-x);
}
.ci-contact-support {
  @include icon(contact-support);
}
.ci-contact {
  @include icon(contact);
}
.ci-customer-stories {
  @include icon(customer-stories);
}
.ci-design {
  @include icon(design);
}
.ci-donations {
  @include icon(donations);
}
.ci-favorite-campaigns {
  @include icon(favorite-campaigns);
}
.ci-fundraising-ideas {
  @include icon(fundraising-ideas);
}
.ci-gallery {
  @include icon(gallery);
}
.ci-guides {
  @include icon(guides);
}
.ci-heart {
  @include icon(heart);
}
.ci-help-center {
  @include icon(help-center);
}
.ci-icon-popup-warning {
  @include icon(icon-popup-warning);
}
.ci-impact {
  @include icon(impact);
}
.ci-left-arrow {
  @include icon(left-arrow);
}
.ci-logout {
  @include icon(logout);
}
.ci-magnify-glass-hover {
  @include icon(magnify-glass-hover);
}
.ci-megaphone {
  @include icon(megaphone);
}
.ci-mobile-top-map {
  @include icon(mobile-top-map);
}
.ci-mobile {
  @include icon(mobile);
}
.ci-mobile_iPad {
  @include icon(mobile_iPad);
}
.ci-mobile_iPhone {
  @include icon(mobile_iPhone);
}
.ci-mobile_top-supporters-will-love {
  @include icon(mobile_top-supporters-will-love);
}
.ci-newsletter {
  @include icon(newsletter);
}
.ci-piggy-bank-icon {
  @include icon(piggy-bank-icon);
}
.ci-powered-by-pixabay {
  @include icon(powered-by-pixabay);
}
.ci-press-media {
  @include icon(press-media);
}
.ci-pricing_cc-explained {
  @include icon(pricing_cc-explained);
}
.ci-profile {
  @include icon(profile);
}
.ci-reports-big-picture {
  @include icon(reports-big-picture);
}
.ci-reports-global-reports {
  @include icon(reports-global-reports);
}
.ci-reports-heart {
  @include icon(reports-heart);
}
.ci-reports-multiple-campaigns {
  @include icon(reports-multiple-campaigns);
}
.ci-right-arrow {
  @include icon(right-arrow);
}
.ci-salesforce_sf-logo {
  @include icon(salesforce_sf-logo);
}
.ci-salesforce_two-products {
  @include icon(salesforce_two-products);
}
.ci-settings {
  @include icon(settings);
}
.ci-share-circle {
  @include icon(share-circle);
}
.ci-step-one {
  @include icon(step-one);
}
.ci-step-three {
  @include icon(step-three);
}
.ci-step-two {
  @include icon(step-two);
}
.ci-story_first-platform {
  @include icon(story_first-platform);
}
.ci-support {
  @include icon(support);
}
.ci-support_best-practices {
  @include icon(support_best-practices);
}
.ci-support_dedicated-server {
  @include icon(support_dedicated-server);
}
.ci-support_email-chat {
  @include icon(support_email-chat);
}
.ci-support_phone {
  @include icon(support_phone);
}
.ci-support_real-people {
  @include icon(support_real-people);
}
.ci-support_specialized-training {
  @include icon(support_specialized-training);
}
.ci-support_website-design {
  @include icon(support_website-design);
}
.ci-support_white-glove {
  @include icon(support_white-glove);
}
.ci-text {
  @include icon(text);
}
.ci-video {
  @include icon(video);
}
.ci-wave {
  @include icon(wave);
}
.ci-webinars {
  @include icon(webinars);
}


.ci-authenticate {
  @include icon(authenticate);
}

.ci-request-access {
  @include icon(request-access);
}

.ci-making-requests {
  @include icon(making-requests);
}
