/*
  Reset
----------------------------------*/

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}


*, *:before, *:after {
  margin: 0;
  padding: 0;
}

//body, html {
//  height: 100%;
//}

body {
  position: relative;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  border: none;
}


