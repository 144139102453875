/*
	Proxima Nova
*/

@import "bourbon";

@include font-face("ProximaNovaRegular",
	"https://479e8b7059efef8d55a8-8c1f1d4d1f2574e4cd164a0f2a6bcea0.ssl.cf2.rackcdn.com/ProximaNova-Reg-webfont",
	$file-formats: eot woff ttf svg);

@include font-face("ProximaNovaSemibold",
	"https://479e8b7059efef8d55a8-8c1f1d4d1f2574e4cd164a0f2a6bcea0.ssl.cf2.rackcdn.com/ProximaNova-Sbold-webfont",
	$file-formats: eot woff ttf svg);

@include font-face("ProximaNovaBold",
	"https://479e8b7059efef8d55a8-8c1f1d4d1f2574e4cd164a0f2a6bcea0.ssl.cf2.rackcdn.com/ProximaNova-Bold-webfont",
	$file-formats: eot woff ttf svg);

@include font-face("ProximaNovaBlack",
	"https://479e8b7059efef8d55a8-8c1f1d4d1f2574e4cd164a0f2a6bcea0.ssl.cf2.rackcdn.com/ProximaNova-Black-webfont",
	$file-formats: eot woff ttf svg);

@include font-face("ProximaNovaExtrabold",
	"https://479e8b7059efef8d55a8-8c1f1d4d1f2574e4cd164a0f2a6bcea0.ssl.cf2.rackcdn.com/ProximaNova-Xbold-webfont",
	$file-formats: eot woff ttf svg);

@include font-face("ProximaNovaThin",
	"https://479e8b7059efef8d55a8-8c1f1d4d1f2574e4cd164a0f2a6bcea0.ssl.cf2.rackcdn.com/ProximaNova-Thin-webfont",
	$file-formats: eot woff ttf svg);

@include font-face("ProximaNovaCondensedSemibold",
	"https://479e8b7059efef8d55a8-8c1f1d4d1f2574e4cd164a0f2a6bcea0.ssl.cf2.rackcdn.com/proximanovacond-semibold-webfont",
	$file-formats: eot woff ttf svg);

@include font-face("ProximaNovaCondensedRegular",
	"https://479e8b7059efef8d55a8-8c1f1d4d1f2574e4cd164a0f2a6bcea0.ssl.cf2.rackcdn.com/proximanovacond-regular-webfont",
	$file-formats: eot woff ttf svg);
