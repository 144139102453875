@import "bourbon";

/*-----------------------
	MuseoSansRounded
-----------------------*/
@include font-face(MuseoSansRounded,
	"https://983a19dd0c368a4b1e57-0cb7d1946e41dc50c3f11b74195916fd.ssl.cf1.rackcdn.com/fonts/MuseoSansRounded-web/MuseoSansRounded-100",
	100,
	$file-formats: eot woff ttf svg);

@include font-face(MuseoSansRounded,
	"https://983a19dd0c368a4b1e57-0cb7d1946e41dc50c3f11b74195916fd.ssl.cf1.rackcdn.com/fonts/MuseoSansRounded-web/MuseoSansRounded-300",
	300,
	$file-formats: eot woff ttf svg);

@include font-face(MuseoSansRounded,
	"https://983a19dd0c368a4b1e57-0cb7d1946e41dc50c3f11b74195916fd.ssl.cf1.rackcdn.com/fonts/MuseoSansRounded-web/MuseoSansRounded-500",
	500,
	$file-formats: eot woff ttf svg);

@include font-face(MuseoSansRounded,
	"https://983a19dd0c368a4b1e57-0cb7d1946e41dc50c3f11b74195916fd.ssl.cf1.rackcdn.com/fonts/MuseoSansRounded-web/MuseoSansRounded-700",
	700,
	$file-formats: eot woff ttf svg);

@include font-face(MuseoSansRounded,
	"https://983a19dd0c368a4b1e57-0cb7d1946e41dc50c3f11b74195916fd.ssl.cf1.rackcdn.com/fonts/MuseoSansRounded-web/MuseoSansRounded-900",
	900,
	$file-formats: eot woff ttf svg);
