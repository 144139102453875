@import "./fonts/Proximanova";
@import "./fonts/MuseoSansRounded";

$OpenSans: 'Open Sans', Arial, Helvetica, sans-serif;
$MuseoSansRounded: 'Museo Sans Rounded', Verdana, Geneva, sans-serif;

$font-family-open-sans:  "Open Sans", Arial, Helvetica, sans-serif;
$font-family-museo-sans: MuseoSansRounded, Verdana, Geneva, sans-serif;
$font-family-merriweather: Merriweather, Cambria, Georgia, serif;
$font-family-font-awesome: FontAwesome;

body {
  font-family: $OpenSans;
}
