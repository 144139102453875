@import "bourbon";
@import "neat";
@import "reset";
@import "colors";
@import "fonts";
@import "classy-icons";

html {
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	height: 100%;
}

body {
  min-height: 100%;
}

.devportal-header {
	&_inner {
		width: 100%;
		height: 100px;
		background: $blueGray;
		color: $offWhite;
		font-size: 12px;
	}

	&_nav {
		float: left;
		padding: 0 0 0 30px;
		text-transform: uppercase;

		.fa {
			font-size: 50px;
			display: block;
			padding: 20px 0 0 0;
			float: left;
		}

		.ci-classy-logo {
			font-size: 54px;
			padding: 17px 4px 0 0;
		}

		&-span {
			margin: 33px 0 0 13px;
			float: left;
			height: 34px;
			border-left: 1px solid #808080;
			padding-left: 12px;
			font: 600 13px/33px $font-family-museo-sans;
			letter-spacing: 1px;
		}

		&-link {
			color: $white;
		}
	}

	&_right-nav {
		height: 100px;
		float: right;
		font-size: 14px;

		i, span {
			padding: 42px 0 0 8px;
			display: inline-block;
			float: left;
		}

		&-span {
			margin: -3px 0 0 0;
		}

		&-link {
			display: none;
			margin: 5px 25px 0 0;
			color: $gray;
		}
	}
}

.devportal-main {
	width: 100%;
	height: calc(100% - 100px);
	position: absolute;
	top: 100px;
	left: 0;
	background-color: $white;

	&_inner {
		display: table;
		height: 100%;
	}

	&_sidebar {
		width: 275px;
		display: table-cell;
		height: calc(100% - 100px);
		padding: 50px 20px 40px 45px;
		background: $blueSilver;
		line-height: 1.8;

	  &-title {
	  	font-size: 19px;
	  	font-weight: 700;
	  	color: $blueGray;
	  }

		&-list {
	    list-style: none;
	    padding-left: 15px;
	    margin-bottom: 15px;

	    &-item {
	      padding: 5px 0;
	    }
	  }

	  &-link {
	    color: $solidGray;
	    text-decoration: none;
		font-size: 14px;
	  }

		.fa {
			color: $solidGray;
			font-size:12px;
			padding-left:3px;
		}
	}

	&_body {
		padding: 20px 175px 160px 50px;
		color: $blockSlate;
		background-color: $white;
		display: table-cell;

		a {
			color: #36bff7;
		}

		p, ol {
			line-height: 1.75;
			margin: 0 0 25px 0;
		}

		.page-header {
			color: $grayBlack;
			font-family: $font-family-museo-sans;
			font-size: 40px;
			padding: 0px 0 50px;
			font-weight: 300;
			line-height: 17px;
			margin-top: 37px;
		}

		.page-subheader {
			color: $adminGreen;
			font-family: $font-family-museo-sans;
			text-transform: uppercase;
			font-size: 17px;
      		font-weight: 800;
			line-height: 5;
			letter-spacing: 1px;
		}

	  	h3 {
		  text-transform: uppercase;
		  font-size: 17px;
		  margin: 40px 0 30px 0;
		}

	  	h4 {
		  color: $solidGray;
		  text-transform: uppercase;
		  font-size: 16px;
		  margin: 40px 0 30px 0;
		}

    &-list {
      list-style-type: disc;

      li {
        margin-left: 50px;
      }
    }
	}

	&_footer {
		padding: 50px 0 30px 0;
	  background-color: $blockSlate;
	  width: 100%;
	  text-align: center;

    &-link {
      color: $white;
      text-decoration: none;
    }

		&-inner {
	    display: inline-block;
	    vertical-align: top;
	    color: $white;
	    font-family: 'MuseoSansRounded','Open Sans',sans-serif;
    	font-weight: 100;

	    &-tagline {
	    	display: block;
	    	font-family: 'MuseoSansRounded','Open Sans',sans-serif;
    		font-weight: 100;
    		font-size: 15px;
    		padding-bottom: 7px;
	    }

	    &-contact {
	    	display: block;
	    	font-size: 11px;

        a {
          color: #36bff7;
        }
	    }

		}
	}
}

.row {
	display: table-row;

    &-footer {
        position: absolute;
        width: 100%;
    }
}

.request-access-btn {
	display: block;
	float: right;
	margin: 28px 40px 0 5px;
	padding: 10px 30px;
  border: 2px solid $white;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.2);
  text-decoration: none;
	transition: all, 0.2s, ease-in-out;

	&:hover{
		background: #f77462;
		border-color: #f77462;
		color: #fff;
	}

  p {
  	color: $offWhite;
  }
}

.btn {
	padding: 10px 20px 10px 20px;
  border-radius: 30px;
  text-decoration: none;
  text-align:center;
	font-size: 15px;
	font-weight: 600;
	min-width: 196px;
	display: inline-block;

	&.coral {
	  background: $coral;
	  color: $white;

	  &:hover {
			opacity:.9;
		}
	}

	&.grey {
		border:2px solid $solidGray;
		color:$solidGray;
		margin-left:20px;

		&:hover {
			color: $grayBlack;
			border: 2px solid $grayBlack;
			cursor: pointer;
		}
	}

}

/** Request-Token page styling **/
.request-token {
	&_list {
		display: block;
    padding: 0 0 10px 35px;
	}
}

/** Welcome Splash Screen **/
.welcome-header {
	font: 500 36px/50px $font-family-museo-sans;
	color:$blueGray;
	margin: 120px 0 30px;
}

.welcome-container {
	@include outer-container;
	padding: 20px;
	margin: 10px auto;
	overflow: auto;

	&_inner {
		width:50%;
		float:left;
	}
}

.welcome-quick-links {
	position: relative;
	margin-top: 100px;
	padding: 100px 45px;
	width: 100%;
	background-color: #edf1f2;
	border-top: 1px solid #bec0c0;
	overflow:auto;

	&_container {
		@include outer-container;
		max-width: 72em;
		margin: 0 auto;
	}

	&_inner {
		float: left;
		display: block;
		width: 28%;
		margin-right:7%;

		&:nth-child(3){
			margin-right:0%;
		}
	}

	&_icon {
		color: $aquamarine;
		display: block;
		font-size:53px;
	}

	&-header {
		font-family: $font-family-museo-sans;
		font-size: 16px;
		color: $grayBlack;
		margin-top: 18px;
		margin-bottom: 14px;
		font-weight: 900;
		text-transform: uppercase;
	}

	dd{
		font-family: "Open Sans",sans-serif;
		font-size: 14px;
		font-weight: 400;
		color: $solidGray;
		line-height: 1.764;
	}
}

/** Getting Started Page **/
.get-started {
	position: relative;
	margin-top: 100px;
	padding: 100px 45px;
	width: 100%;
	background-color: #edf1f2;
	border-top: 1px solid #bec0c0;
	overflow:auto;

	&_container {
		@include outer-container;
		max-width: 72em;
		margin: 0 auto;
    padding-bottom: 50px;
	}

	&_inner {
		float: left;
		display: block;
		width: 28%;
		margin-right:7%;

		&:nth-child(3){
			margin-right:0%;
		}
	}

	&_icon {
		color: $aquamarine;
		display: block;
		font-size:53px;
	}

	&_header {
		font-family: $font-family-museo-sans;
		font-size: 15px;
		color: $grayBlack;
		margin-top: 18px;
		margin-bottom: 14px;
		font-weight: 900;
		text-transform: uppercase;
	}

	dd{
		font-family: "Open Sans",sans-serif;
		font-size: 14px;
		font-weight: 400;
		color: $solidGray;
		line-height: 1.764;
	}
}

.hbspt-form {
	width: 100%;
	margin: 20px auto;

	.hs-input {
		font: 400 13px Open Sans, Verdana;
		color: #666;
		border: 1px solid #ccc;
		border-bottom-left-radius: 30px;
		border-top-left-radius: 30px;
		border-right: none;
		padding: 16px 40px 15px 13px;
		height: 35px;

		&:focus {
			outline: none;
		}
	}

	.hs-button {
		display: inline-block;
		margin-left: -5px;
		border: none;
		border-bottom-right-radius: 30px;
		border-top-right-radius: 30px;
		background-color: $brightBlue;
		color: $white;
		text-transform: uppercase;
		padding: 6px 21px 12px;
		font: 700 12px/23px $font-family-open-sans;
		letter-spacing: 1px;
		cursor: pointer;
		-webkit-appearance: none;
		height: 35px;

		&:hover {
			opacity: .95;
		}
	}

	.hs-error-msgs.inputs-list {
		position: absolute;
		top: 62px;
		color: #F77462;
		list-style: none;
		font: 12px Open Sans, Verdan, Serif;
	}
	div {
		float: left;
	}

}
.hs-submitted-message {
	background: #5FD3C2;
	padding: 6px;
	border-radius: 3px;
	color: #fff;
	margin: 0 auto;
	display: block;
	font-family: Open Sans, Verdan, Serif;
	font-size: 13px;
	float: none !important;
	text-align: center;
}

/** Request Token Page **/
.req-token {
	&_container {
		@include outer-container;
	}

	&_field {
		padding: 10px 0 10px 0;
		clear: both;
	}

	&_label {
		@include span-columns(4);
		font-size: 15px;
	}

	&_input {
		@include span-columns(6);
		@include omega();

		line-height: 20px;
	}

	&_list {
		list-style-type: none;
	}

	&_required {
		color: red;
		vertical-align: super;
		font-size: 12px;
	}

	&_textarea {
		width: 48.8%;
		height: 50px;
	}

	&_submit {
		display: inline-block;
		margin-left: -5px;
		border: none;
		border-radius: 30px;
		background-color: $brightBlue;
		color: $white;
		text-transform: uppercase;
		padding: 6px 21px 12px;
		font: 700 12px/23px $font-family-open-sans;
		letter-spacing: 1px;
		cursor: pointer;
		-webkit-appearance: none;
		height: 35px;

		&:hover {
			opacity: .95;
		}
	}
}

/** Quick Links - I want to... **/

.want-to-grid-outer {
	margin: 70px auto;
	border-top: 1px solid #bec0c0;
	padding: 50px 0 0;

	.want-to-grid {
		@include outer-container;

		h2 {
			font: 300 36px/50px MuseoSansRounded, Verdana, Geneva, sans-serif;
			color: #444b55;
			text-align: center;
			margin: 20px 0;
		}

		dl {
			clear: both;
			margin-top: 48px;
			overflow: auto;

			a {
				display: block;
				border-radius: 4px;
				width: 23.5%;
				border: 1px solid #A1A8AC;
				float: left;
				margin-right: 2%;
				padding: 2%;
				text-align: center;
				height: 235px;
				color: $gray;
				@include transition(all 0.1s ease-in);

				&:hover {
					color: white;
					border-color: $brightBlue;

					dt {
						color: $brightBlue;
					}

				}

				img{
					display:block;
					margin:18px auto 22px;
				}

				dt {
					font-family: $font-family-museo-sans;
					font-size: 16px;
					font-weight: 900;
					color: #444B55;
					text-transform: uppercase;
					margin: 15px 0;
					@include transition(all 0.1s ease-in);
				}
			}

			a:last-child {
				margin-right: 0;
			}
		}
	}
}


/** Syntax highlighting blocks **/
.hljs {
	max-width: 800px;
  margin: -9px 0 20px 0;
}

.contact {
	font-size: 11px;
}


@media only screen and (max-width: 900px) {
	.devportal-main_footer {
		display: none;

	}
}

table {
	border-spacing: 0;
	padding: 0 0 20px 0;
    table-layout: fixed;
    width: 100%;

	th {
		text-align: left;
		border-bottom-style: solid;
		border-bottom-width: thin;
		border-bottom-color: $grayLightest;
		vertical-align: bottom;
		padding: 0 30px 10px 0;
	}

	td {
		font-size: 14px;
		line-height: 20px;
		padding: 20px 0 0 0;
		vertical-align: top;
        word-wrap: break-word;
	}
}



footer.classy {
	position: relative;
	padding: 100px 0 0px 0;
	border-top: 1px solid $grayLightest;
	min-width: 1124px;

	/*
		Containers
	  ----------------------------------*/

	.content {
		@include outer-container;
	}

	.col {
		float: left;
		padding: 0 60px 0 0;
		min-height: 200px;
	}

	.primary {
		@include span-columns(10);
	}

	.secondary {
		@include span-columns(2);
		@include omega();
		text-align: right;

	}

	/*
		Elements
	  ----------------------------------*/

	color: $gray;

	a {
		color: $gray;
		&:hover{
			color:$blueGray;
		}
	}

	small {
		@include span-columns(12);
		padding: 100px 0 50px 0;
		text-align: center;
	}


	h2 {
		width: 101px;
		height: 46px;
		display: inline-block;

		i {
			font-size: 45px;
			color: $blueGray;
		}
	}

	h5 {
		color:$grayBlack;
		padding: 0 0 10px 0;
		font-size: 13px;
		font-weight: 600;
		text-transform: uppercase;
	}

	li {
		padding: 0 0 10px 0;
		font-size: 13px;
	}

	.tel {
		padding: 0 10px 5px 0;
		font-size: 13px;
	}

	.social {
		padding: 0 10px 0 0;

		a {
			font-size: 17px;
			margin: 0 5px 0 0;
			span {
				display: none;
			}
		}
	}

	small {
		font-size: 12px;

		.bullet {
			padding: 0 10px;
			font-size: 3px;
		}

		.ci-wave {
			font-size: 11px;
			color: #36bff7;
		}
	}


}


/*
		Responsive tiger styles
		----------------------------------*/



@media (max-width: 600px) {
  .welcome-container{
	  padding: 2%;
	  width: 96%;

	  .welcome-container_inner{
		  float:none;
		  width:100%;

		  img{
			  width: 100%;
			  height: auto;
		  }

		  .welcome-header{
			  margin-top:30px;
		  }

		  .btn{
			  display: block;
			  margin-bottom: 20px;
			  margin-left:0;
		  }
	  }
  }


	.want-to-grid-outer .want-to-grid{
		dl{
			margin-top:20px;
			a{
				width: 80%;
				float:none;
				margin: 20px auto;
				height: 193px;

				&:last-child{
					margin-right:auto;
				}
			}
		}
	}

	.welcome-quick-links {
		margin-top: 75px;
		padding-top:75px;
		padding-bottom: 25px;

		.welcome-quick-links_inner {
			float: none;
			width: 100%;
			margin-bottom: 40px;

			.hbspt-form {

				div{
					float:none;
				}
				.hs-input {

					border-right: 1px solid #ccc;
					padding: 16px 13px 15px 13px;
					height: 35px;
					width: 100%;
					border-radius: 30px;
					margin-bottom: 10px;
				}

				.hs-button{
					height: 35px;
					margin-left: 0;
					border-radius: 30px;
				}
			}
		}

	}

	footer.classy {
		padding: 10px 0;
		min-width: 1px;

		.col {
			float: none;
			padding: 0 50px 0 0;
		}

		.primary,
		.secondary{
			display: none;
		}

		small {
			@include span-columns(11);
			@include shift(.5);
			padding: 0;
			line-height: 21px;

			span {
				display: none;
				&:nth-child(1), &:nth-child(3) {
					display: inline-block;
				}
			}
			.bullet {
				display: none;

				&:nth-child(2) {
					display: inline;
					padding: 0 10px;
				}
			}

		}

	}
}
































