/*
	Colors we're for sure using...
*/

$white: rgb(255, 255, 255); // #FFFFFF
$offWhite: rgb(248, 248, 248); // #F8F8F8 - admin form background

$nearlyBlack: rgb(35,42,47); // #232a2f

$blockSlate: rgb(54,62,69); // #363e45
$grayBlack: rgb(44, 52, 59); // #2C343B - dark background behind blocks, header text

// Only 5 shades of gray!
$iconGray: rgb(181,181,181); // #b5b5b5
$gray: rgb(161, 168, 172); // #A1A8AC
$lightGray: rgb(210, 210, 210); // #d3d3d3 - form fields: border color, placeholder text, field icon
$solidGray: rgb(114, 126, 131); // #727e83 - Advanced designer bg color
$blueGray: rgb(68, 75, 85); // #444B55 - Page template bg color, form label, input text

// accent colors
$adminGreen: rgb(115, 215, 200); //#74d8c9
$brightBlue: rgb(54, 191, 247); // #36BFF7
$aquamarine: rgb(117,210,196); // #75d2c4 - Nav green button color
$greenNeon: rgb(89,233,196); // #59e9c4 - green check mark

$coral: rgb(245, 117, 102); //#f57566 - error messages, asterisk

// Will be renamed! don't worry
$silver: rgb(240, 240, 240); // #F0F0F0 - impact level background, WYZIWYG editor, background circles, footer
$blueSilver: rgb(237, 241, 242); // #edf1f2

// Draggable form blocks/inputs
$blockHead: rgb(235, 235, 235); // #eaeaea
$blockBody: rgb(244, 244, 244); // #f3f3f3

// Social Colors
$facebookBlue: rgb(59, 89, 152);

/*
	FRS only colors
*/
$red: rgb(205, 68, 67); // #CD4443 - error state for public pages

/*
	Admin colors
*/

$electric: rgb(67, 205, 253); // #43CDFD
$orchid: rgb(172, 136, 217); // #AC88D9 - unpublished text purple

// From SC-Admin-Styleguide.psd
$orange: rgb(246, 95, 45); // #F65F2D

// Rebrand colours
$aquamarine: rgb(96, 211, 194); // #60D3C2
$skyblue: rgb(52, 176, 219); // #34B0DB - tooltip

$black: rgb(0, 0, 0); // #000000
$blackShade: rgba(0, 0, 0, .5); // rgba(#000000, 0.5)

$fieldBg: rgb(84, 89, 97); // #545961

$grayLightest: rgb(191, 193, 196); // #BFC1C4
$grayDarker: rgb(33, 39, 45); // #21272D
